
            @import "@/assets/sass/vars.portal.scss";
          






































































































































































































.table-special {
    width: 100%;
    font-size: 10px;

    th {
        border: 1px solid $gray-500;
        padding: 5px;
        background: $gray-100;
    }

    td {
        border: 1px solid $gray-500;
        padding: 5px;
    }
}
